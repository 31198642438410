<template>
  <div class="widget-2roomz">
    <a
      class="btn-widget-2roomz"
      :style="widgetStyle"
      :class="{ open: visiblePopup }"
      @click="visiblePopup = !visiblePopup"
    >
      <div
        v-if="options.with_ping && !visiblePopup"
        class="ping-2roomz"
        :style="{ backgroundColor: options.color }"
      ></div>

      <div style="display: flex; align-items: center" v-if="!visiblePopup">
        <img
          :src="widgetLogo"
          v-if="options.type == 'site'"
          :style="imageStyle"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          v-if="options.type == 'chat'"
        >
          <path
            d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z"
          />
          <path
            d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z"
          />
        </svg>
      </div>

      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
        />
      </svg>
    </a>

    <div class="modal-2roomz" :class="{ open: visiblePopup }">
      <div class="modal-bg-2roomz modal-exit-2roomz"></div>
      <div class="modal-container-2roomz" v-if="url">
        <iframe :src="url" name="" allowpaymentrequest=""></iframe>
      </div>
    </div>
    <div class="wg-overlay-2roomz" v-if="visiblePopup"></div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      visiblePopup: false,
      options: {
        hotel: "",
        color: "#05a88b",
        path: "",
        with_ping: true,
        type: "site",
        icon: "",
      },
      url: null,
    };
  },

  computed: {
    widgetLogo() {
      if (this.options.icon !== undefined && this.options.icon) {
        return (
          "https://s3.eu-central-1.amazonaws.com/widget.sandboxdg.com/" +
          this.options.icon
        );
      }

      if (this.options.hotel == "pushkarka.2roomz.com")
        return "https://s3.eu-central-1.amazonaws.com/widget.sandboxdg.com/logo_white_2.png";

      return "https://s3.eu-central-1.amazonaws.com/widget.sandboxdg.com/logo_white.png";
    },

    widgetStyle() {
      var sty = {
        backgroundColor: this.options.color,
        ...this.options?.style_button,
      };

      return sty;
    },

    imageStyle() {
      let width = "36px";
      let height = "36px";

      if (
        this.options.hotel === "pushkarka.2roomz.com" ||
        this.options.icon === "logo_white_2.png"
      ) {
        width = "28px";
        height = "28px";
      }

      return {
        width,
        height,
      };
    },
  },

  created() {
    if (window.tworoomz_widget_2) {
      this.options = window.tworoomz_widget_2;
      this.url = "https://" + this.options.hotel + this.options.path;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.widget-2roomz {
  z-index: 9304938308;
  position: fixed;
  bottom: 20px;
  right: 20px;
}
.wg-overlay-2roomz {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}
.btn-widget-2roomz {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  transition: transform 167ms cubic-bezier(0.33, 0, 0, 1) 0s;
  transform-origin: center center;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  z-index: 99999;
}

.ping-2roomz {
  position: absolute;
  width: fit-content;
  border-radius: 40px;
  width: 2.75rem;
  height: 2.75rem;
  opacity: 0.75;
  animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.btn-widget-2roomz:hover {
  transition: transform 250ms cubic-bezier(0.33, 0, 0, 1) 0s;
  transform: scale(1.1);
}

.btn-widget-2roomz:active {
  transform: scale(0.85);
  transition: transform 134ms cubic-bezier(0.45, 0, 0.2, 1) 0s;
}

.btn-widget-2roomz svg {
  color: #fff;
  height: 1.5rem;
  width: 1.5rem;
}

.modal-2roomz {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  bottom: 123px;
  right: 50px;
  height: 600px;
  width: 370px;
  z-index: 9999999;
}
.modal-2roomz.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.modal-bg-2roomz {
  position: absolute;
  width: 100%;
  height: 100%;
}
.modal-container-2roomz {
  border-radius: 10px;
  background: #fff;
  position: relative;
  padding: 10px;
}
.modal-container-2roomz iframe {
  min-height: 600px;
  width: 100%;
  border: none;
  z-index: 9304938308;
}
@media only screen and (max-width: 480px) {
  .modal-2roomz {
    width: 320px;
    height: 550px;
    right: 10px;
    bottom: 30px;
  }

  .btn-widget-2roomz.open {
    bottom: 585px;
    right: 10px;
    width: 32px !important;
    height: 32px !important;
  }

  .btn-widget-2roomz.open svg {
    color: #fff;
    height: 1rem;
    width: 1rem;
  }
  .modal-container-2roomz iframe {
    min-height: 550px;
  }
}
</style>
